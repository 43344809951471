import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./press-article.scss"

const PressArticleTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        {/* <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </header> */}
        <section  className="blog-post-body"
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <div className="media-contact-containter">
          <div className="media-contact-heading">
              Media Contact
          </div>
          <div className="media-contact-address">
              Indiqube Edge, Bellandur, Bengaluru, Karnataka - 560103
          </div>
          <div className="media-contact-hyperlink">
            <a href = "mailto: media@solvezy.com">media@solvezy.com</a>
          </div>
          {/* <hr></hr>
          <div className="media-contact-heading">
              Media Kit
          </div>
          <div className="media-contact-hyperlink">
          <a href={`/Media_Kit.zip`} download>
                Download here
              </a>
          </div> */}
        </div>
      </article>
    </Layout>
  )
}

export default PressArticleTemplate

export const pageQuery = graphql`
  query PressArticleBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        place
      }
    }
  }
`
